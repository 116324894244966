.login-background {
  background-color: #f3f5f7; 
  min-height: 100vh;
}

.login-container {
  background-color: #f3f5f7 !important;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.announcement-banner {
  background-color: #ffffff;
  color: #2c662d;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 2px solid #2c662d;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.announcement-banner strong {
  font-size: 18px;
}

.login-card {
  max-width: 475px;
}

.workspace-picker-card {
  max-width: 800px;
}

.login-logo {
  width: 300px;
}

.workspace-picker-logo {
  width: 250px;
}

.workspace-item {
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.workspace-link {
  text-decoration: none;
  display: block;
  color: #495057;
}

.form-input {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.modal-button {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
